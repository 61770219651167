<template>
  <div>
    <el-dialog title="教练绑定" :visible.sync="dialogVisible" width="50%" :destroy-on-close="true">
      <div style="margin-bottom:10px;">
        <el-radio v-model="type" label="remove" @input="toggle">教练解绑</el-radio>
        <el-radio v-model="type" label="add" @input="toggle">绑定机构</el-radio>
      </div>
      <el-form :inline="true" class="demo-form-inline" v-if="type == 'add'">
        <el-form-item label="机构">
          <el-input v-model="phone" placeholder="输入机构名称或电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
      <p v-if="type == 'add'">请选择要绑定的机构</p>
      <p v-if="type == 'remove'">请取消勾选解绑的机构</p>
      <el-table ref="multipleTable" :data="all_mechanism" tooltip-effect="dark" style="width: 100%"
        @selection-change="changeSelect" v-loading="loading">
        <el-table-column type="selection" :selectable="checkSelectable" width="55">
        </el-table-column>
        <el-table-column label="机构名" prop="mechanism_name">
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="{ row }">
            <div v-if="type == 'add'">
              {{ row.parent_id === 0 ? "主店" : "分店" }}
            </div>
            <div v-if="type == 'remove'">
              {{ row.mechanism_parent_id === row.mechanism_id ? "主店" : "分店" }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="类型">
          <template slot-scope="{ row }">
            <div>
              {{ row.isBind ? "已绑定" : "未绑定" }}
            </div>
          </template>
        </el-table-column> -->
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button style="margin-right: 20px;" @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type == 'add'" type="primary" @click="openConfirm">绑定</el-button>
        <el-button v-if="type == 'remove'" type="primary" @click="openConfirm">解绑</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      type: "remove",
      dialogVisible: false,
      all_mechanism: [],
      coach_id: "",
      coachBind: [],
      mecList: [],
      selection: [],
      phone:'',
      loading:false
    };
  },

  mounted() { },

  methods: {
    search() {
      this.loading = true
      let url = "/user/mastermechanism/queryByMessage";
      const numberReg = /^\d/
      let data = {
        type: "teach_paypal",
        pageSize: 30,
        currentPage: 1,
        status: 2,
      };
      numberReg.test(this.phone)?(data.mechanism_telephone = this.phone) : (data.mechanism_name = this.phone)
      this.$axios
        .get(url, {
          params:data
        })
        .then((res) => {
          this.loading = false
          if (res.data.code == 0) {
            this.all_mechanism = res.data.data.rows.reverse();
            this.mecList = this.all_mechanism
            this.checkSelected()
          }
        });
    },
    changeSelect(v) {
      this.selection = v;
    },
    display(show, cid, mec) {
      this.dialogVisible = show;
      this.coach_id = cid;
      this.coachBind = mec;
      this.all_mechanism = mec;
      setTimeout(() => {
        this.all_mechanism.forEach((item) => {
          this.$refs.multipleTable.toggleRowSelection(item, true);
        });
      }, 200);

    },
    openConfirm() {
      let text = this.type == 'add' ? '确认将教练绑定到机构？' : '确认解除教练与机构的绑定？'
      this.$confirm(text, {
        callback: this.batchBind,
      });
    },
    // 批量绑定
    batchBind(v) {
      if (v == "confirm") {
        let originids = this.coachBind.map(item=>{return item.mechanism_id})
        let selids = this.selection.map((item) => {
          let key = this.type == 'remove' ? 'mechanism_id': 'id'
          return item[key];
        });
        this.$axios({
          url: "user/coachMechanismConnected/updateCoachMechanism",
          data: {
            mechanism_ids: [...originids,...selids],
            id: this.coach_id,
          },
          method: "post",
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message("修改成功");
            this.dialogVisible = false;
            this.$emit("success");
          } else {
            this.$message(res.data.message);
          }
        });
      }
    },
    toggle() {
      if (this.type == 'remove') {
        this.all_mechanism = this.coachBind
      } else {
        this.all_mechanism = this.mecList
      }
      this.checkSelected()
    },
    //是否选中
    checkSelected() {
      let bind_ids = this.coachBind.reduce((pre, cur) => {
        pre.push(cur.mechanism_id);
        return pre;
      }, []);
      let key = this.type == 'remove' ? 'mechanism_id' : 'id'
      setTimeout(() => {
        this.all_mechanism.forEach((item) => {
          if (bind_ids.includes(item[key])) {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          } else {
            this.$refs.multipleTable.toggleRowSelection(item, false);
          }
        });
      }, 200);
    },
    // 是否可以勾选
    checkSelectable(row, index) {
      let key = this.type == 'remove' ? 'mechanism_id' : 'id'
      let flag = true;
      for (let i = 0; i < this.coachBind.length; i++) {
        if (this.coachBind[i].mechanism_id == row[key]) {
          flag = false;
          break;
        }
      }
      if (this.type == "remove") {
        return !flag;
      } else {
        return flag;
      }
    },
  },
};
</script>
  
<style lang="scss" scoped></style>