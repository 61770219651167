<template>
  <div class="">
    <div class="cash-wrap">
      <div class="cash-item">
        <div class="cash-name">账户余额</div>
        <div class="cash-value">¥{{ cashInfo.cash }}</div>
      </div>
      <div class="cash-item">
        <div class="cash-name">累计收益</div>
        <div class="cash-value">¥{{ cashInfo.totalCash }}</div>
      </div>
      <div class="cash-item">
        <div class="cash-name">今日收益</div>
        <div class="cash-value">
          ¥{{ cashInfo.toDayCash + cashInfo.toDayCash_other }}
        </div>
      </div>
      <div class="cash-item">
        <div class="cash-name">今日支出</div>
        <div class="cash-value">¥{{ cashInfo.toDayCashExpend }}</div>
      </div>
    </div>
    <el-form :inline="true">
      <el-form-item label="收益类型">
        <el-select v-model="type" placeholder="选择收益类型" clearable>
          <el-option label="课程结算" :value="5"></el-option>
          <el-option label="邀请收益" :value="3"></el-option>
          <el-option label="支出/提现" :value="4"></el-option>

          <el-option label="公会收益" value="7,8"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type="{ row }">
        <div>
          {{ typeMap[row.type] }}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="success" size="small" v-if="row.status === 1"
            >已到账</el-tag
          >
          <el-tag type="info" size="small" v-if="row.status === 2"
            >未到账</el-tag
          >
          <el-tag type="warning" size="small" v-if="row.status === 3"
            >已退款</el-tag
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "说明", value: "remark" },
        { name: "金额(元)", value: "amount" },
        { name: "类型", value: "type", type: "custom" },
        { name: "时间", value: "create_time" },
      ],
      Total: 0,
      type: "",
      id: "",
      typeMap: {
        1: "注册",
        2: "邀请奖励",
        3: "奖励",
        4: "支出/提现",
        5: "课程结算",
        6: "无忧担保",
        7: "公会课程佣金",
        8: "公会会员佣金",
      },
      load: false,
      cashInfo: {
        cash: 0,
        totalCash: 0,
        toDayCash: 0,
        toDayCash_other: 0,
        toDayCashExpend: 0,
      },
    };
  },

  mounted() {
    this.id = this.$route.query.id;
  },

  methods: {
    init() {
      if (!this.load) {
        this.load = true;
        this.getList(1);
        this.getCoachCash();
      }
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    getCoachCash() {
      this.$axios({
        url: "/user/coach/queryCash",
        params: {
          coach_id: this.id,
        },
      }).then((res) => {
        this.cashInfo = res.data.data;
      });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/coachCashRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          coach_id: this.id,
          types: this.type || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cash-wrap {
  display: flex;
  margin-bottom: 10px;
}
.cash-item {
  padding: 10px 20px;
  margin-right: 20px;
  background-color: rgba(243, 202, 140, 0.7);
  border-radius: 10px;
  min-width: 100px;
}
.cash-name {
  font-size: 14px;
}
.cash-value {
  font-size: 20px;
  margin-top: 10px;
}
</style>