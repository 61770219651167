import { render, staticRenderFns } from "./coachIncome.vue?vue&type=template&id=70419642&scoped=true&"
import script from "./coachIncome.vue?vue&type=script&lang=js&"
export * from "./coachIncome.vue?vue&type=script&lang=js&"
import style0 from "./coachIncome.vue?vue&type=style&index=0&id=70419642&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70419642",
  null
  
)

export default component.exports