<template>
    <div class="container">
      <el-row>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="状态">
            <el-select
              v-model="status"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">搜索</el-button>
          </el-form-item>
          <el-button type="warning" @click="addCourse">发布需求</el-button>
        </el-form>
        <!-- 列表 -->
        <el-table
          :data="DataList"
          :fit="true"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column label="id" prop="id"></el-table-column>
          <el-table-column label="课程名称" prop="title"></el-table-column>
          <el-table-column
            label="课程类目"
            prop="categories"
          ></el-table-column>
          <el-table-column label="总课时数" prop="course_num"></el-table-column>
          <el-table-column label="服务方式" prop="service_type">
              <template slot-scope="{ row }">
                  <p v-if="row.service_type == 1">海马场地</p>
                  <p v-if="row.service_type == 2">上门教学</p>
                  <p v-if="row.service_type == 3">自有场地</p>
              </template>
          </el-table-column>
          <el-table-column label="课程总价" prop="amount"></el-table-column>
          <el-table-column label="创建时间" prop="create_time" width="160px"></el-table-column>
          <el-table-column label="状态" prop="status">
              <template slot-scope="{ row }">
                 <p>{{options[row.status].label}}</p>
              </template>
          </el-table-column>
          <el-table-column label="操作" width="200px">
            <template slot-scope="{ row }">
              <div class="flex flex-wrap">
                  <el-button type="primary" @click="edit(row)" size="small"
                  v-if="row.status < 5"
                >编辑</el-button
              >
              <el-button type="primary" @click="details(row)" size="small"
              v-if="row.status >=5"
                >查看</el-button
              >
              <el-button type="warning" @click="details(row)" size="small"
              v-if="row.status == 0"
                >审核</el-button
              >
              <el-button
                type="success"
                v-if="row.status == 1 || row.status == 4"
                @click="update(row, 3)"
                size="small"
                >上架</el-button
              >
              <el-button
                type="danger"
                v-if="row.status == 3"
                @click="update(row, 4)"
                size="small"
                >下架</el-button
              >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage"
          :total="Total"
        ></paging-fy>
      </el-row>

    </div>
  </template>
      
      <script>
  import mechanismSelect from "@/components/select/mechanismSelect.vue";
  import userSelect from "@/components/select/userSelect.vue";
  export default {
    components: {
      mechanismSelect,
      userSelect,
    },
    data() {
      return {
        DataList: [],
        is_loading: true,
        currentPage: 1,
        Total: 0,
        status: '',
        categories:'',
        create_type:'',
        options: [
          {
            value: 0,
            label: "待审核",
          },
          {
            value: 1,
            label: "通过",
          },
          {
            value: 2,
            label: "拒绝",
          },
          {
            value: 3,
            label: "上架中",
          },
          {
            value: 4,
            label: "已下架",
          },
          {
            value: 5,
            label: "已接单",
          },
        ],
        user_id: "",
        mechanism_id: "",
        price: "",
        listPageChild:[],
        cid:''
      };
    },
    mounted() {
      this.cid = this.$route.query.id 
    },
    methods: {
    init(){
        if(this.cid && this.DataList.length == 0){
            this.getList()
        }
    },
      // 发布需求
      addCourse(){
          this.$router.push({ name: "generateCourse"});
      },
      details(row) {
        this.$router.push({ name: "courseNeedDetails", query: { id: row.id } });
      },
      /**编辑 */
      edit(row) {
        this.$router.push({ name: "generateCourse", query: { id: row.id } });
      },
      //更新状态
      update(row, status) {
        let url = "/user/userCourseNeed/update";
        let data = {
          id: row.id,
          status,
        };
        this.$axios({
          url,
          data,
          method: "post",
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({ message: "成功" });
            this.getList();
          } else {
            this.$message({ message: res.data.message });
          }
        });
      },
      //需求列表
      getList() {
        let url = "/user/userCourseNeed/queryManagerListPage";
        let params = {
          pageSize: 10,
          currentPage: this.currentPage,
          status:this.status || null,
            coach_id:this.cid,
            create_type:2
        };
        this.$axios
          .get(url, {
            params,
          })
          .then((res) => {
            this.DataList = res.data.data.rows;
            this.Total = res.data.data.total;
          });
      },
      search() {
        this.currentPage = 1;
        this.getList();
      },
      changeCurrentPage(v) {
        this.currentPage = v;
        this.getList();
      },
    },
  };
  </script>
      
      <style lang="less">
  .expand-form {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 20px;
    line-height: 1.5;
    .el-form-item {
      width: 40%;
    }
  }
  .el-button + .el-button {
    margin-left: 0;
  }
  </style>
      