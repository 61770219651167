<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="场馆名称">
       <el-input v-model="name" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #share="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.sharing === 'no'">不共享</el-tag>
          <el-tag type="success" size="small" v-if="row.sharing === 'share'"
            >共享</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
            <el-button  type="primary" size="small" @click="details(row)"
            >详情</el-button
          >
          <el-button  type="danger" size="small" @click="del(row)"
            >删除</el-button
          >
          
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "城市", value: "city" },
        { name: "地址", value: "address" },
        { name: "共享", value: "share", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 2,
      coach_id:'',
      name:''
    };
  },

  mounted() {
    this.coach_id = this.$route.query.id 
  },

  methods: {
    details(row){
        this.$router.push({
        name: "venueDetails",
        params: {
          id:row.id
        },
      });
    },
    init(){
        if(this.coach_id && this.DataList.length == 0){
            this.getList(1);
        }
    },
    del(row){
      this.$confirm("确定删除").then(()=>{
         this.$get('/user/mechanism/venue/delete',{id:row.id}).then(res=>{
          this.getList(1);
        })
      })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/mechanism/venue/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          coach_id:this.coach_id,
          name:this.name || null
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>