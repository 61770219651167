<template>
  <div>
    <div class="part-title">执教经历</div>
        <div class="flex flex-wrap" v-if="record.length > 0">
          <div class="record" v-for="item in record" :key="item.id">
            <el-descriptions :column="1" border>
              <el-descriptions-item
                label="执教机构"
                :labelStyle="{ 'min-width': '120px' }"
              >
                <span>{{ item.institution }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="执教时间"
                >{{ item.start_time.split(" ")[0] }}-{{
                  item.end_time.split(" ")[0]
                }}</el-descriptions-item
              >
              <el-descriptions-item label="执教职位">{{
                item.position
              }}</el-descriptions-item>
              <el-descriptions-item label="执教内容">{{
                item.content
              }}</el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <div v-else class="noInfo">暂无信息</div>
  </div>
</template>

<script>
export default {
 
  data() {
    return {
      coach_id:'',
      record:[],
      coachInfo:{}
    };
  },

  mounted() {
      this.coach_id = this.$route.query.id 
  },

  methods: {
    init(c){
        if(this.coach_id && this.record.length == 0){
             this.coachInfo = c
            this.getCoachRecode()
        }
    },
    //执教经历
    getCoachRecode() {
      let url = "/user/coachTeachingRecord/query";
      let params = {
        coach_id: this.coach_id,
        attestation_record_id: this.coachInfo.attestation_record_id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.record = res.data.data;
        });
    },
  },
};
</script>