<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="">
        <el-button type="success" @click="getList(1)">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #pic="{ row }">
        <div>
          <img :src="row.pic" alt="" style="width: 40px; height: 40px" />
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button @click="del(row)">删除</el-button>
        </div>
      </template>
    </auto-table>

    <el-dialog :visible.sync="show" width="30%" title="新增">
      <el-form label-width="80px">
        <el-form-item label="图标" required>
          <avatar-uploader @getImgUrl="(v) => (form.pic = v)"></avatar-uploader>
        </el-form-item>
        <el-form-item label="技能名称" required>
          <el-input type="" placeholder="技能名称" v-model="form.name">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="addRecord"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "coachSkill",
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "图片", value: "pic", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",
      coach_id: "",
      show: false,
      form: {
        name: "",
        pic: "",
      },
    };
  },
  mounted() {
    this.coach_id = this.$route.query.id;
    this.getList(1);
  },

  methods: {
    init(){
       if(this.coach_id && this.DataList.length == 0){
           this.getList(1);
        }
    },
    del(row) {
      this.$confirm("确认删除").then((res) => {
        this.$get("/user/coachSkill/delete", { id: row.id }).then((res) => {
          this.getList(1);
        });
      });
    },
    add() {
      this.form = {
        name: "",
        pic: "",
      };
      this.show = true;
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/coachSkill/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    addRecord() {
      this.$axios({
        url: "/user/coachSkill/insert",
        data: {
          coach_id: this.coach_id,
          name: this.form.name,
          pic: this.form.pic,
        },
        method: "post",
      }).then((res) => {
        this.getList(1);
        this.show = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>