<template>
    <div class="container">
      <el-row>
        <!-- 列表 -->
        <el-table
          :data="DataList"
          :fit="true"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column label="id" prop="id"></el-table-column>
          <el-table-column label="课程名称" prop="map.courseTitle"></el-table-column>
          <el-table-column label="用户昵称" prop="map.nickName"></el-table-column>
          <el-table-column
            label="结算金额"
            prop="cash"
          ></el-table-column>
          <el-table-column label="类型" prop="type"></el-table-column>
          <el-table-column label="创建时间" prop="create_time" width="160px"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage"
          :total="Total"
        ></paging-fy>
      </el-row>

    </div>
  </template>
      
      <script>
  import mechanismSelect from "@/components/select/mechanismSelect.vue";
  import userSelect from "@/components/select/userSelect.vue";
  export default {
    components: {
      mechanismSelect,
      userSelect,
    },
    data() {
      return {
        cid:'',
        DataList: [],
        is_loading: true,
        currentPage: 1,
        Total: 0,
        status: '',
        categories:'',
        create_type:'',
        options: [
          {
            value: 0,
            label: "待审核",
          },
          {
            value: 1,
            label: "通过",
          },
          {
            value: 2,
            label: "拒绝",
          },
          {
            value: 3,
            label: "上架中",
          },
          {
            value: 4,
            label: "已下架",
          },
          {
            value: 5,
            label: "已接单",
          },
        ],
        user_id: "",
        mechanism_id: "",
        price: "",
        listPageChild:[]
      };
    },
    mounted() {
     this.cid = this.$route.query.id 
      this.GetListPageChild()
    },
    methods: {
    init(){
        if(this.cid && this.DataList.length == 0){
            this.getList()
        }
    },
      GetListPageChild() {
         this.$axios({
          url: "/user/mechanismCategory/queryListPageChild",
          params: {
            type: 2,
            status: 2,
            source: "课程",
          },
        }).then((res) => {
          this.listPageChild = res.data.data;
        });
      },
      // 发布需求
      addCourse(){
          this.$router.push({ name: "generateCourse"});
      },
      details(row) {
        this.$router.push({ name: "courseNeedDetails", query: { id: row.id } });
      },
      /**编辑 */
      edit(row) {
        this.$router.push({ name: "generateCourse", query: { id: row.id } });
      },
      //更新状态
      update(row, status) {
        let url = "/user/userCourseNeed/update";
        let data = {
          id: row.id,
          status,
        };
        this.$axios({
          url,
          data,
          method: "post",
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({ message: "成功" });
            this.getList();
          } else {
            this.$message({ message: res.data.message });
          }
        });
      },
      //需求列表
      getList() {
        let url = "/user/userRecommenderIncomeLog/queryIncomeLog";
        let params = {
          pageSize: 10,
          currentPage: this.currentPage,
          coach_id : this.cid
        };
        this.$axios
          .get(url, {
            params,
          })
          .then((res) => {
            this.DataList = res.data.data.rows;
            this.Total = res.data.data.rows.length;
          });
      },
      search() {
        this.currentPage = 1;
        this.getList();
      },
      changeCurrentPage(v) {
        this.currentPage = v;
        this.getList();
      },
    },
  };
  </script>
      
      <style lang="less">
  .expand-form {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 20px;
    line-height: 1.5;
    .el-form-item {
      width: 40%;
    }
  }
  .el-button + .el-button {
    margin-left: 0;
  }
  </style>
      