<template>
  <div>
    <div style="margin: 10px 0">
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="invitation_choice"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            style="margin-left: 50px"
            type="primary"
            @click="invitation_search"
            >搜索</el-button
          >
        </div>
        <div>
          <el-table
            :data="invitationList"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="id" prop="coach_id"></el-table-column>
            <el-table-column label="姓名" prop="coach_name"></el-table-column>
            <el-table-column label="电话" prop="coach_phone"></el-table-column>
             <el-table-column label="类型" prop="coach_phone">
               <template slot-scope="{ row }">
                <p v-if="row.type == 2">邀请注册</p>
                <p v-if="row.type == 3">开会员</p>
                 <p v-if="row.type == 4">创建公会</p>
              </template>
             </el-table-column>
            <el-table-column
              label="邀请时间"
              prop="create_time"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="{ row }">
                <p v-if="row.status == 0">邀请中</p>
                <p v-if="row.status == 1">邀请成功</p>
                 <p v-if="row.status == 4">邀请成功</p>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <paging-fy
            @currentPageChange="invitationCurrentPage"
            :currentPage="invitationPage"
            :total="invitationTotal"
          ></paging-fy>
        </div>

        <!-- 添加奖励 -->
    <el-dialog title="添加奖励" :visible.sync="cashVisible" width="30%">
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="奖励金额">
            <el-input v-model="cashAmount"></el-input>
          </el-form-item>
          <el-form-item label="奖励类型">
            <!-- <el-radio v-model="cash_type" label="1">注册</el-radio> -->
            <el-radio v-model="cash_type" label="2">邀请</el-radio>
            <el-radio v-model="cash_type" label="3">奖励</el-radio>
          </el-form-item>
          <el-form-item label="奖励标题">
            <el-input type="textarea" v-model="remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cashVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCash">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      cashVisible:false,
      coach_id: '',
      cash_type: '',
      cashAmount: '',
      remark: '',
       invitationPage: 1,
      invitationTotal: 1,
      invitation_status: 2,
      invitationList: [],
      value:'',
       options: [
        {
          value: 2,
          label: "全部",
        },
        {
          value: 0,
          label: "未认证",
        },
        {
          value: 1,
          label: "已认证",
        },
      ],
    };
  },

  mounted() {
    this.coach_id = this.$route.query.id 
  },

  methods: {
    init(){
        if(this.coach_id && this.invitationList.length == 0){
            this.invitation()
        }
    },
     invitationCurrentPage(v) {
      this.invitationPage = v;
      this.invitation();
    },
     invitation_choice(v) {
      this.invitation_status = v;
    },
     invitation_search() {
      this.invitation();
      this.invitationPage = 1;
    },
    // 邀请列表
    invitation() {
      let data = {
        invitation_id: this.coach_id,
        pageSize: 10,
        currentPage: this.invitationPage,
      };
      if (this.invitation_status != 2) {
        data.status = this.invitation_status;
      }
      this.$get("user/coachInvitationRecord/queryManagerListPage", data).then(
        (res) => {
          this.invitationList = res.data.data.rows;
          this.invitationTotal = res.data.data.total;
        }
      );
    },
    // 增加奖励金额
    addCash() {
      if (this.cashAmount <= 0) {
        this.$message({ message: "请填写金额" });
        return;
      }
      let url = "/user/coach/updateCash";
      let data = {
        id: this.coach_id,
        type: this.cash_type,
        cash: this.cashAmount,
        remark: this.remark,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "添加成功" });
          this.cashVisible = false;
        }
      });
    },
  },
};
</script>