<template>
  <div class="container">
       <el-form :inline="true">
        <el-form-item label="状态">
          <el-select v-model="status" placeholder="请选择" clearable>
            <el-option
              v-for="item in status_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #sex="{ row }">
         <p v-if="row.coach_sex == 0">未知</p>
            <p v-if="row.coach_sex == 1">男</p>
            <p v-if="row.coach_sex == 2">女</p>
      </template>

      <template #status="{ row }">
       <el-tag type="success" v-if="row.status == 1">审核通过</el-tag>
            <el-tag type="info" v-if="row.status == 0">待审核</el-tag>
            <el-tag type="danger" v-if="row.status == 2">审核拒绝</el-tag>
      </template>
        <template #handle="{ row }">
        <el-button type="primary" @click="toDetails(row)" size="small"
                >查看详情</el-button
              >
      </template>
    </auto-table>
  </div>
</template>
      
      <script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "姓名", value: "real_name" },
        { name: "电话", value: "coach_phone" },
        { name: "性别", value: "sex" , type: "custom"},
        { name: "提交时间", value: "create_time" },
        { name: "状态", value: "status" ,type: "custom"},
         { name: "审核人", value: "admin_name"},
        { name: "操作", value: "handle" , type: "custom"},
      ],
      Total: 0,
      coach_id: "",
      status:null,
      status_options: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "已通过",
        },
        {
          value: 2,
          label: "已拒绝",
        },
      ],
    };
  },
  mounted() {
    this.coach_id = this.$route.query.id;
  },
  methods: {
    init() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    search(){
   this.getList(1);
    },
   toDetails(row) {
      this.$router.push({
        name: "coachCardDetails",
        query: { id: row.id, coach_id: row.coach_id },
      });
    },
    //列表
    getList(v) {
      if (!this.coach_id) {
        return;
      }
      let page = v || 1;
      let url = "/user/coachAttestationRecord/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: page,
        coach_id: this.coach_id,
        status:this.status
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
        });
    },
  },
};
</script>
      
      <style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;
  .el-form-item {
    width: 40%;
  }
}
.el-button + .el-button {
  margin-left: 0;
}
</style>
      